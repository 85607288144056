<template lang="">
    <div class="font-worksans">
        <div class="relative font-worksan">
            <div class="">
                <img
                    src="@/assets/atg_about_page_truck.webp"
                    class="h-full"
                    alt=""
                />
            </div>
            <p
                class="absolute top-1/2 flex justify-center w-full text-3xl text-white uppercase"
            >
                about us
            </p>
        </div>

        <!-- content section -->
        <div class="lg:flex justify-center p-7 md:p-16">
            <img src="@/assets/atg_about_img_2.png" alt="" />
            <div class="flex flex-col items-center space-y-12 justify-center">
                <p class="max-w-md text-center">
                    We help CDL Truck Drivers find jobs in their area. With over
                    400 active jobs in over 12,000 zip codes, chances are we
                    have CDL Trucking jobs in your area.
                </p>

                <button
                    class="uppercase text-lg bg-[#123456] hover:bg-red-500 rounded-full px-6 py-2 text-white tracking-widest bottom-0"
                >
                    <a href="tel:888-900-0484"> call now </a>
                </button>
            </div>
        </div>
    </div>
</template>
<script setup></script>
<style lang="css">
.aboutMainbg {
    background-image: url("../assets/atg_about_page_truck.webp");
}
</style>
