<template lang="">
    <div class="relative h-screen font-worksans">
        <div class="absolute inset-0">
            <img
                src="@/assets/atg_truck_home_2.png"
                alt=""
                class="object-cover h-full"
            />
        </div>
        <div class="absolute bottom-20 flex justify-center w-full">
            <div class="text-center space-y-8">
                <p class="text-xl text-[#123456] font-bold tracking-wider">
                    We Help People Find People
                </p>
                <button
                    class="uppercase text-sm bg-[#123456] hover:bg-red-500 rounded-full px-6 py-2 text-white tracking-widest"
                >
                    <a href="tel:888-900-0484"> call now </a>
                </button>
            </div>
        </div>
    </div>

    <!-- our services section -->
    <div class="flex justify-center p-12 font-worksans">
        <div class="max-w-5xl text-center space-y-7">
            <h1 class="text-4xl uppercase text-red-600">Our services</h1>
            <p>We specialize in CDL A Truck Driver Recruiting</p>
            <p class="">
                Are you looking for CDL A driver Applications or Pre-screened
                CDL Qualified drivers? We specialize in helping trucking
                compaies with either.
            </p>
            <p>
                Are you looking for a steady stream of applications that your
                team can sort through to find just the right candidates? We can
                make that happen for you.
            </p>
            <p>
                Are you looking to only talk with pre-screened qualified CDL A
                drivers that meet your every criteria, we are experts at
                delivering that Whatever your needs are, we can help you get the
                right CDL A drivers into your trucks.
            </p>
            <button
                class="uppercase text-sm bg-[#123456] hover:bg-red-500 rounded-full px-6 py-2 text-white tracking-widest"
            >
                <router-link to="/employer-services">
                    view services
                </router-link>
            </button>
        </div>
    </div>

    <!-- Image part goes here -->
    <div
        class="relative bg-cover bg-fixed bg-center bg-no-repeat h-96 truckBg"
    ></div>

    <!-- bottom section -->
    <div class="grid grid-cols-12 md:p-32 text-center bg-gray-50 font-worksans">
        <div
            class="col-span-12 bg-white md:col-span-4 space-y-4 flex flex-col items-center justify-between border p-8"
        >
            <div class="">
                <img
                    src="@/assets/atg_Icon_1.png"
                    alt="atg open job list icon"
                />
            </div>

            <h1 class="text-2xl font-bold">Open Jobs List</h1>
            <p>
                Need a CDL A Truck Driving Job? We have hundreds of CDL Driving
                Jobs located in thousands of zip codes throughout the 48 states.
                Click the link to access our entire job list then give us a
                call.
            </p>
            <button
                class="uppercase text-sm bg-[#123456] hover:bg-red-500 rounded-full px-6 py-2 text-white tracking-widest"
            >
                <router-link to="/jobs"> atg job search </router-link>
            </button>
        </div>
        <div
            class="col-span-12 bg-white md:col-span-4 space-y-4 flex flex-col items-center justify-between border-t border-b p-8"
        >
            <div class="">
                <img
                    src="@/assets/atg_Icon_2.png"
                    alt="atg open job list icon"
                />
            </div>

            <h1 class="text-2xl font-bold">1 Minute App</h1>
            <p>We have a simple 1 minute application</p>
            <button
                class="uppercase text-sm bg-[#123456] hover:bg-red-500 rounded-full px-6 py-2 text-white tracking-widest"
            >
                <a
                    href="https://zfrmz.com/WGCpzT3XMcUZwRITqSGI?referrername=ATG_WS"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    atg 1 minute app
                </a>
            </button>
        </div>
        <div
            class="col-span-12 bg-white md:col-span-4 space-y-4 flex flex-col items-center justify-between border p-8"
        >
            <div class="">
                <img
                    src="@/assets/atg_Icon_3.png"
                    alt="atg open job list icon"
                />
            </div>
            <h1 class="text-2xl font-bold">Need Drivers ?</h1>
            <p>
                Your need Qualified CDL Drivers. We are here to help. We work
                with trucking companies and can help you get the drivers you
                need to keep your business running strong. Check out our
                services for trucking companies.
            </p>
            <button
                class="uppercase text-sm bg-[#123456] hover:bg-red-500 rounded-full px-6 py-2 text-white tracking-widest bottom-0"
            >
                <router-link to="/employer-services">
                    our services
                </router-link>
            </button>
        </div>
    </div>
</template>
<script setup></script>
<style lang="css">
.mainBg {
    background-image: url("../assets/atg_truck.webp");
}

.truckBg {
    background-image: url("../assets/atg_truck2.webp");
}
</style>
